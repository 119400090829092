import {ReactElement, useEffect, useRef, useState} from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalBody from "./modalComponents/FrameModalBody";
import classNames from "classnames";
import FrameButton from "../buttons/FrameButton";
import confetti from "canvas-confetti";
import {FaFacebook, FaLinkedinIn, FaMailBulk, FaTwitter} from "react-icons/fa";
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "@ciaran0/react-share";
import GoVipLevelUpAnimation from "../GoVipLevelUpAnimation";
import {IoCloseSharp} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {NftMintTransaction, UsersApi, Token} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetRedeemableNftOwnerships} from "@/hooks/useGetRedeemableNftOwnerships";
import {addError} from "@/redux/meta/metaActions";
import Toast from "@/components/Toast";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    isOpen: boolean;
    toggle: () => void;
    level: number;
    dpayGained: number;
    isLootboxAwarded: boolean;
}

function GoVipLevelUpModal(props: Props): ReactElement {
    const navigate = useNavigate();
    const [
        headerVisible,
        setHeaderVisible,
    ] = useState<boolean>(false);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();

    const [
        lootboxMintRecord,
        setlootboxMintRecord,
    ] = useState<NftMintTransaction>(undefined);
    const [
        isLootboxRecordLoading,
        setIsLootboxRecordLoading,
    ] = useState<boolean>(undefined);
    const [
        claimLootboxTriggered,
        setClaimLootboxTriggered,
    ] = useState<boolean>(false);
    const [
        showLootboxMintFailedToast,
        setShowLootboxMintFailedToast,
    ] = useState<boolean>(false);
    const {refetch: refetchRedeemableNftOwnerships} = useGetRedeemableNftOwnerships(fullToken as Token);

    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const animationTimeout: number = 500;
    const titleMessage: string = "I leveled up my GoVIP on DevourGO!";
    const shareMessage: string = `I reached GoVIP level ${props.level} on DevourGO and earned $${props.dpayGained}.00 ${import.meta.env.VITE_TOKEN_NAME}!\nTry it out with me here!`;
    const shareURL: string = "https://devourgo.io/go-vip-dashboard"; // import.meta.env.VITE_WEB_URL
    const {isOnDarkMode} = useThemePreference();

    useEffect(() => {
        if (props.isOpen && !headerVisible) {
            setTimeout(() => {
                setHeaderVisible(true);
            }, animationTimeout);
        } else if (!props.isOpen && headerVisible) {
            setHeaderVisible(false);
        }
    }, [
        props.isOpen,
        headerVisible,
    ]);

    useEffect(() => {
        // check if lootbox has been minted
        if (props.isLootboxAwarded && props.isOpen && !intervalRef.current) {
            setIsLootboxRecordLoading(true);
            intervalRef.current = setInterval(getLootboxMintStatus, 2000);

        } else if (!props.isOpen) {
            setClaimLootboxTriggered(false);
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, [
        props.isOpen,
        props.isLootboxAwarded,
    ]);


    useEffect(() => {
        if (isLootboxRecordLoading === false && intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, [isLootboxRecordLoading]);

    useEffect(() => {
        if (props.isLootboxAwarded && claimLootboxTriggered && isLootboxRecordLoading === false) {
            props.toggle();

            if (lootboxMintRecord?.isComplete) {
                navigate("/go-vip-dashboard", {state: {openNftRewardsDrawer: true}});
            } else if (lootboxMintRecord && !lootboxMintRecord.isComplete) {
                setShowLootboxMintFailedToast(true);
            }
            setClaimLootboxTriggered(false);
        }
    }, [
        props.isLootboxAwarded,
        lootboxMintRecord,
        claimLootboxTriggered,
        isLootboxRecordLoading,
    ]);


    async function getLootboxMintStatus(): Promise<void> {
        try {
            const record: NftMintTransaction = await new UsersApi(getConfig(fullToken)).getUserLevelLootboxMintRecord();
            if (record.isComplete) {
                await refetchRedeemableNftOwnerships();
            }
            setlootboxMintRecord(record);
            setIsLootboxRecordLoading(false);
        } catch (e) {
            if (e.status !== 404) {
                // keeps polling if get 404
                dispatch(await addError(e));
                setIsLootboxRecordLoading(false);
            }
        }
    }

    function toggleFade(): void {
        if (props.isLootboxAwarded) {
            setClaimLootboxTriggered(true);
        } else {
            props.toggle();
        }
    }

    if (props.isOpen && headerVisible) {
        [
            0.25,
            0.5,
            0.75,
        ].forEach(x => {
            confetti({
                particleCount: 150,
                startVelocity: 35,
                angle: 90,
                gravity: 0.2,
                spread: 360,
                ticks: 800,
                origin: {
                    x: x,
                    y: -0.5,
                },
                zIndex: 50,
            });
        });
    }

    function getLootboxNumber(): number {
        switch (props.level) {
            case 12:
                return 3;
            case 7:
                return 2;
            default:
                return 1;
        }
    }

    function getLootboxNumberText(): string {
        switch (props.level) {
            case 12:
                return "third";
            case 7:
                return "second";
            default:
                return "first";
        }
    }

    function handleToastDismissal(): void {
        setShowLootboxMintFailedToast(false);
    }

    function renderDpayAwardedLevelInfo(): ReactElement {
        return (
            <>
                <div
                    className={classNames(
                        "go-vip-level-up-modal_content-con",
                        "go-vip-level-up-modal_dpay-info-con",
                        "go-vip-level-up-modal_display-row", {
                            "go-vip-level-up-modal_content-con_visible": headerVisible,
                        },
                    )}
                >
                    <div className="go-vip-level-up-modal_dpay-info-con_label">
                        {import.meta.env.VITE_TOKEN_NAME} Reward
                    </div>
                    <div className="go-vip-level-up-modal_dpay-info-con_amount">+ ${props.dpayGained}.00</div>
                </div>
                <div
                    className={classNames(
                        "go-vip-level-up-modal_content-con",
                        "go-vip-level-up-modal_lootbox", {
                            "go-vip-level-up-modal_content-con_visible": headerVisible,
                        },
                    )}
                >
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/govip-level-up-lootbox-image.webp`}
                        alt="Lootbox Award"
                        className="go-vip-level-up-modal_lootbox_image"
                    />

                    <div>
                        <span className="go-vip-level-up-modal_lootbox_origin">
							DevourGO
                        </span>
                        <div className="go-vip-level-up-modal_lootbox_title">GoVIP Loot Box</div>
                    </div>

                    <div className="go-vip-level-up-modal_lootbox_message">
                        You have successfully achieved {import.meta.env.VITE_TOKEN_NAME} and GoVIP Loot Box #{getLootboxNumber()}.
                        Redeem your {getLootboxNumberText()} exciting reward from My Rewards!
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Toast
                message="Sorry, the minting process for your lootbox NFT has failed. Please try again in an hour."
                variant="error"
                isOpen={showLootboxMintFailedToast}
                showButton={false}
                onDismiss={handleToastDismissal}
                duration={3000}
            />
            <FrameOneModal
                isOpen={props.isOpen}
                toggle={toggleFade}
                contentClassName={classNames(
                    "go-vip-level-up-modal",
                    {"lootbox-awarded-level": props.isLootboxAwarded},
                )}
            >
                <div className="go-vip-level-up-modal_close govip-nft-redeem-confirm-modal_body_close-wrapper">
                    <IoCloseSharp onClick={props.toggle}/>
                </div>
                <FrameModalBody className="go-vip-level-up-modal_body">

                    {!isOnDarkMode &&
                        <div className="go-vip-level-up-modal_level-up-animation">
                            <GoVipLevelUpAnimation level={props.level}/>
                        </div>
                    }
                    <div
                        className={classNames("go-vip-level-up-modal_header-con", {
                            "go-vip-level-up-modal_header-con_visible": headerVisible,
                        })}
                    >
                        <h3>
                            {props.level === 3 && props.isLootboxAwarded
                                ? `You've completed level ${props.level}`
                                : `You've reached level ${props.level}!`
                            }
                        </h3>
                    </div>

                    {props.isLootboxAwarded
                        ? renderDpayAwardedLevelInfo()
                        : <>
                            <div
                                className={classNames("go-vip-level-up-modal_content-con", {
                                    "go-vip-level-up-modal_content-con_visible": headerVisible,
                                })}
                            >
                                <p className={"go-vip-level-up-modal_small-text-con"}>
                                    You earned
                                </p>
                            </div>

                            <div
                                className={classNames(
                                    "go-vip-level-up-modal_content-con",
                                    "go-vip-level-up-modal_dpay-info-con",
                                    "go-vip-level-up-modal_display-row", {
                                        "go-vip-level-up-modal_content-con_visible": headerVisible,
                                    },
                                )}
                            >
                                <div className="go-vip-level-up-modal_display-row">
                                    <img
                                        className={"go-vip-level-up-modal_dpay-icon-con"}
                                        src={`${import.meta.env.VITE_CDN_URL}/images/token-white.webp`}
                                        alt={"dpay icon"}
                                    />
                                    <h5 className="go-vip-level-up-modal_dpay-text-con">
                                        {import.meta.env.VITE_TOKEN_NAME}
                                    </h5>
                                </div>
                                <div className="go-vip-level-up-modal_dpay-info-con_amount">+
                                    ${props.dpayGained}.00
                                </div>
                            </div>

                            <div
                                className={classNames("go-vip-level-up-modal_content-con", {
                                    "go-vip-level-up-modal_content-con_visible": headerVisible,
                                })}
                            >
                                <p className={"go-vip-level-up-modal_small-text-con"}>
                                    Check your email for a bigger level up reward!
                                </p>
                            </div>
                        </>
                    }

                    <div
                        className={classNames("go-vip-level-up-modal_cta-con", {
                            "go-vip-level-up-modal_cta-con_visible": headerVisible,
                        })}
                    >
                        <FrameButton
                            color="purple"
                            size="normal"
                            onClick={toggleFade}
                            showSpinnerWithoutGlobalLoading={props.isLootboxAwarded && claimLootboxTriggered && isLootboxRecordLoading}
                        >
                            {props.isLootboxAwarded
                                ? "Claim Lootbox"
                                : "Continue"}
                        </FrameButton>
                    </div>

                    <div className="go-vip-level-up-modal_footer">
                        <div className="go-vip-level-up-modal_footer_text">Share on social media</div>

                        <div className="go-vip-level-up-modal_footer_socials">
                            <TwitterShareButton
                                title={shareMessage}
                                url={shareURL}
                                hashtags={[
                                    "DevourGO",
                                    "GoVip",
                                    "GoFrens",
                                ]}
                                className="go-vip-level-up-modal_bottom-socials-btn"
                                style={{
                                    backgroundColor: "none",
                                }}
                            >
                                <FaTwitter/>
                            </TwitterShareButton>

                            <FacebookShareButton
                                quote={shareMessage}
                                url={shareURL}
                                hashtag={"#DevourGO"}
                                className="go-vip-level-up-modal_bottom-socials-btn"
                                style={{
                                    backgroundColor: "none",
                                }}
                            >
                                <FaFacebook/>
                            </FacebookShareButton>

                            <LinkedinShareButton
                                url={shareURL}
                                title={titleMessage}
                                summary={shareMessage}
                                source={shareURL}
                                className="go-vip-level-up-modal_bottom-socials-btn"
                                style={{
                                    backgroundColor: "none",
                                }}
                            >
                                <FaLinkedinIn/>
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={shareURL}
                                subject={titleMessage}
                                body={shareMessage}
                                className="go-vip-level-up-modal_bottom-socials-btn"
                                style={{
                                    backgroundColor: "none",
                                }}
                            >
                                <FaMailBulk/>
                            </EmailShareButton>
                        </div>
                    </div>
                </FrameModalBody>
            </FrameOneModal>
        </>
    );
}

export default GoVipLevelUpModal;
